<template>
  <div>
    <v-tooltip top v-if="item && item.integrationStatus">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" small>
          <v-icon :color="getStatusColor(item.integrationStatus)">{{
            getStatusIcon(item.integrationStatus)
          }}</v-icon>
        </v-btn>
      </template>
      <span style="font-size: 12px">{{ item.integrationStatus }}</span>
    </v-tooltip>
  </div>
</template>
<script>
export default {
  data: () => ({
    item: {},
  }),
  mounted() {
    this.item = this.params.data;
  },
  methods: {
    getStatusColor(status) {
      switch (status.toLowerCase()) {
        case "action required":
          return "orange";
        case "open":
          return "grey";
        case "not required":
          return "grey";
        case "not started":
          return "grey";
        case "cancelled":
          return "grey";
        case "approved":
          return "green";
        case "complete":
          return "green";
        case "completed":
          return "green";
        case "delivered":
          return "green";
        case "in progress":
          return "indigo";
        case "created":
          return "blue";
        case "submitted":
          return "blue";
        case "resubmitted":
          return "cyan";
        case "draft":
          return "orange";
        case "file generated":
          return "orange";
        case "pending":
          return "orange";
        case status.includes("pending"):
          return "orange";
        case "draft approved":
          return "purple";
        case "revision required":
          return "red";
        case "rejected":
          return "red";
        case "query":
          return "purple";
        case "in transnit":
          return "orange";
        case "failed":
          return "red";
        case "new":
          return "amber";
        case "error":
          return "red";
        case "not created":
          return "red";
        case "in transit":
          return "blue";
        case "validation failed":
          return "red";
        case "inspection failed":
          return "red";
        case "inspection approved":
          return "success";
        case status.toLowerCase().includes("pending"):
          return "orange";
        case status.toLowerCase().includes("approved"):
          return "green";
        case status.toLowerCase().includes("failed"):
          return "red";
        case status.toLowerCase().includes("revoked"):
          return "red";
        default:
          return "grey";
      }
    },
    getStatusIcon(status) {
      switch (status.toLowerCase()) {
        case "action required":
          return "warning";
        case "not required":
          return "close";
        case "cancelled":
          return "cancel";
        case "not started":
          return "schedule";
        case "pending":
          return "pending";
        case "in progress":
          return "sync";
        case "draft":
          return "edit_note";
        case "created":
          return "mark_email_read";
        case "submitted":
          return "mark_email_read";
        case "resubmitted":
          return "mark_email_read";
        case "complete":
          return "task_alt";
        case "completed":
          return "task_alt";
        case "delivered":
          return "task_alt";
        case "file generated":
          return "task";
        case "new":
          return "star";
        case "error":
          return "cancel";
        case "failed":
          return "cancel";
        case "rejected":
          return "cancel";
        case "not created":
          return "error";
        case "approved":
          return "fact_check";
        case "validation failed":
          return "error";
        case "inspection failed":
          return "error";
        case "inspection approved":
          return "fact_check";
        case "in transit":
          return "local_shipping";
        case status.toLowerCase().includes("pending"):
          return "pending";
        case status.toLowerCase().includes("approved"):
          return "fact_check";
        case status.toLowerCase().includes("failed"):
          return "error";
        case status.toLowerCase().includes("revoked"):
          return "error";
        default:
          return "info";
      }
    },
  },
};
</script>