<template>
    <div>
        <v-chip small v-if="item && item.grouping" outlined>{{ item.grouping }}</v-chip>
        </div>
</template>
<script>
export default {
    data: () => ({
        item: {}
    }),
    mounted() {
    this.item = this.params
    console.log(this.item)
  },
}
</script>