<template>
  <div>
    <v-card flat v-if="event && event.id">
      <v-toolbar flat color="transparent">
        <v-toolbar-title>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon class="mr-2" v-on="on" :color="getStatus(event.status).color">{{ getStatus(event.status).icon
              }}</v-icon>
            </template>
            <span>{{ event.status }}</span>
          </v-tooltip>
          {{ event.title }} <span v-if="event.reference">#{{ event.reference }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <!-- <v-spacer></v-spacer> -->

        <v-btn icon id="info-guide" @click="infoStartGuide" fab height="4vh" width="4vh">
          <v-icon>help</v-icon>
        </v-btn>
        <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon text v-bind="attrs" v-on="on" @click="deleteEvent" v-if="allowDelete" :loading="deleteEventLoader">
            <v-icon color="primary">delete</v-icon>
          </v-btn>
        </template>
        <span>Delete Event</span>
      </v-tooltip>
        <v-btn v-if="shipment.documentationStatus == 'OPEN' && (event.status == 'Not Started' || event.status == 'Error')" color="primary" text @click="startEvent"
          :loading="loading">Start Event</v-btn>
        <v-btn text @click="$emit('close'), (selectedTab = 'info')">X</v-btn>
      </v-toolbar>
      <v-card-text>
        <v-tabs v-model="selectedTab" show-arrows>
          <v-tab key="info" href="#info">Info <v-icon right>info</v-icon></v-tab>
          <v-tab key="request" href="#request" v-if="event.request">Request<v-icon right>data_object</v-icon></v-tab>
          <v-tab key="response" href="#response" v-if="event.response">Response <v-icon right>data_object</v-icon></v-tab>
        </v-tabs>
        <v-tabs-items v-model="selectedTab" style="max-height: 70vh; overflow-y: auto">
          <v-tab-item value="info" style="background: var(--v-modal-base) !important">
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-list-item v-if="event.description" id="event-description">
                      <v-list-item-content>
                        <v-list-item-subtitle class="text-wrap">{{
                          event.description
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="event.destinationAddressId && destinationAddress" :loading="loadingAdress">
                      <v-list-item-action>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="secondary">location_on</v-icon>
                          </template>
                          <span>Destination Address</span>
                        </v-tooltip>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ destinationAddress.organisation.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ destinationAddress.addressLine1 }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          {{ destinationAddress.addressLine2 }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          {{ destinationAddress.addressLine3 }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          {{ destinationAddress.addressLine4 }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="event.integrationCode" id="int-code">
                      <v-list-item-action>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="secondary">webhook</v-icon>
                          </template>
                          <span>Integration</span>
                        </v-tooltip>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ event.integrationCode }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          Integration Code
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-subheader v-if="eventActions.length > 0 && shipment.documentationStatus !='CLOSED'" style="font-size: 16px">Event Actions</v-subheader>
                    <v-list-item id="event-actions"
                      v-if="shipment.documentationStatus !='CLOSED'&& event.integrationCode == 'ZA_DHL_Waybill' && (event.status == 'Not Started' || event.status == 'Error' || event.status == 'Submitted')"
                      @click="findOpenWaybill(event)">
                      <v-list-item-action>
                        <v-icon color="primary">merge</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Merge Waybill
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-wrap">
                          Add this shipment to an existing waybill
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <div  v-if="shipment.documentationStatus !='CLOSED'">
                      <v-list-item id="event-actions" :disabled="!action.statuses.includes(event.status)"
                      @click="executeAction(action)" v-for="action in eventActions" :key="action.code">
                      <v-list-item-action>
                        <v-icon :color="action.color">{{ action.icon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ action.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-wrap">
                          {{ action.description }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-progress-circular size="24" v-show="loadingAction && loadingAction == action.code"
                          indeterminate color="primary"></v-progress-circular>
                      </v-list-item-action>
                    </v-list-item>
                    </div>
                    
                    <v-subheader style="font-size: 16px">Event Attachments
                    </v-subheader>
                    <v-list dense style="
                          max-height: 45vh;
                          min-height: 10vh;
                          overflow-y: auto;
                        ">
                      <v-progress-linear v-if="loadingAttachments" indeterminate color="primary"></v-progress-linear>
                      <v-list-item id="event-attachments" v-for="doc in eventAttachments" :key="doc.id">
                        <v-list-item-action class="ml-0 pl-0" style="width: 20px">
                          <v-img v-if="getDocumentIcon(doc.shipmentDocument.name)" class="mr-2"
                            style="height: 30px; width: 30px" :src="getDocumentIcon(doc.shipmentDocument.name)">
                          </v-img>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ doc.shipmentDocument.name }}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action :key="documentKey">
                          <v-row>
                            <v-btn icon v-if="doc.shipmentDocument.name &&
                              doc.shipmentDocument.name.toLowerCase().includes('.pdf')
                              " small @click="previewDocument(doc.shipmentDocument)"><v-icon
                                small>visibility</v-icon></v-btn>
                            <v-btn icon :loading="doc.loading" @click="downloadDocument(doc.shipmentDocument)"
                              small><v-icon small>download</v-icon></v-btn>
                          </v-row>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item id="event-attachments" v-if="eventDocuments.length == 0">
                        <v-list-item-content class="text-center">
                          <span style="color: grey">No attachments.</span>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col cols="12" md="6" v-if="event.data && event.dataType == 'timeline'">
                    <v-timeline align-top dense style="
                          max-height: 45vh;
                          min-height: 10vh;
                          overflow-y: auto;">
                      <v-timeline-item color="primary" small v-for="(log, index) in event.data" :key="index" fill-dot>
                        <strong>{{ log.title }}</strong>
                        <div class="text-caption">
                          {{ formatDate(log.time) }}
                        </div>
                      </v-timeline-item>
                    </v-timeline>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item value="request" style="background: var(--v-modal-base) !important">
            <v-card flat>
              <v-card-text>
                <pre>{{ JSON.stringify(event.request, null, 2) }}</pre>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item value="response" style="background: var(--v-modal-base) !important">
            <v-card flat>
              <v-card-text>
                <pre>{{ JSON.stringify(event.response, null, 2) }}</pre>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>

    <v-dialog v-model="previewModal" :fullscreen="$vuetify.breakpoint.mobile || fullscreen" width="1200px">
      <v-card flat>
        <v-toolbar flat>
          <v-toolbar-title style="font-size: 16px">
            {{ previewItem.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="fullscreen = !fullscreen" icon><v-icon v-if="!fullscreen">fullscreen</v-icon><v-icon
              v-else>fullscreen_exit</v-icon></v-btn>
          <v-btn text @click="$refs.myPdfComponent.print()" icon>
            <v-icon>print</v-icon>
          </v-btn>
          <v-btn icon @click="(previewModal = false), (previewItem = {})">X</v-btn>
        </v-toolbar>
        <v-card-text :style="{
          'max-height': fullscreen ? '88vh' : '75vh',
          'overflow-y': 'auto',
          'background-color': 'var(--v-modal-base) !important',
        }">
          <v-row justify="center">
            <v-col cols="12" md="9" lg="8" xl="6" class="text-center">
              <!-- <v-btn @click="annotate">Annotate</v-btn> -->
              <div ref="pdfBox">
                <pdf ref="myPdfComponent" :src="previewItem.file" v-for="page in previewItem.pages" :key="page"
                  :page="page"></pdf>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="waybillSearch" width="750px" :fullscreen="$vuetify.breakpoint.mobile" persistent>
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            Merge to Existing Waybill
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-chip id="team-select-one" small class="mx-1"
                        v-for="status in dhlStatuses"
                        :color="selectedStatusses.includes(status.id) ? 'green' : 'primary'"
                        :key="status.id" @click="selectStatusses(status.id)">
                        {{ status.name }}</v-chip> 
          <v-spacer></v-spacer>
          <v-btn text @click="waybillSearch = false, waybillItem = {} , selectedStatusses=[1]">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-data-table style="cursor: pointer" @click:row="mergeWaybill" :loading="loadingWaybill" :items="openWaybills"
            :headers="waybillHeaders">
            <template v-slot:item.destinationAdress="{ item }">
              <span>
                {{ formatAddress(item.destinationAdress.addressLine1) }}
                {{ formatAddress(item.destinationAdress.addressLine2) }}
                {{ formatAddress(item.destinationAdress.addressLine3) }}
                {{ formatAddress(item.destinationAdress.addressLine4) }}
              </span>
                  </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="infoStart" max-width="31vw">
      <v-card>
        <v-btn @click="infoStartGuide" icon style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0;"><v-icon
            color="primary" size="30">close</v-icon></v-btn>
        <v-card-title class="d-flex justify-center">
          FRIKO Welcome to Task & Actions - Events!
        </v-card-title>
        <v-card-text>
          <span>
            <p>FRIKO</p>
            <p>FRIKO
              <br>• Map out routes for transporters
              <br>• Add GeoJSON data to locations used determine if a vehicle is within a certain area
              <br>• Calculate accurate waiting times at loading points
              <br>• Calculate distances for billing purposes
              <br>• Pin-point locations for the purpose of reporting and analysis
              <br>• Map out farms that aren't found on Google Maps
            </p>
            If you would like learn how to load POI's into the system, click the button below to start the
            tutorial.
          </span>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="startGuide" color="edit" class="mb-3">Start!</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import dateFormat from "dateformat";
import pdf from "vue-pdf";

import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
import emSteps from './Steps/eventModalSteps'

export default {
  props: ["event", "documents", "shipment","allowDelete"],
  components: {
    pdf,
  },
  data: () => ({
    dhlStatuses:[
      {id: 1, name: 'Created'},
      {id: 2, name: "In Transit"},
    ],
    destinationAddress: {},
    selectedStatusses: [1],
    deleteEventLoader:false,
    loadingAdress: false,
    documentKey: 0,
    eventActions: [],
    eventAttachments: [],
    fullscreen: false,
    loading: false,
    loadingAction: null,
    loadingAttachments: false,
    loadingWaybill: false,
    openWaybills: [],
    previewItem: {},
    previewModal: false,
    selectedTab: "info",
    waybillItem: {},
    waybillHeaders: [
      { text: "Adress Line", value: "destinationAdress", align: "center"},
      { text: "Country", value: "destinationAdress.country", align: "center"},
      { text: "Alias", value: "destinationAdress.alias", align: "center"},
      { text: "File Number", value: "shipment.shipmentFile.fileNumber", align: "center"},
      { text: "Waybill No", value: "reference", align: "center" },
      { text: "Status", value: "status", align: "center" },
      
    ],
    waybillSearch: false,
    infoStart: false,
  }),
  watch: {
    "event.id": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getAttachments(val)
          this.getEventActions(val);
          this.adressData()

        }
      },
    },
    "selectedStatusses": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getOpenWaybills()
        }
      },
    },

  },
  computed: {
    eventDocuments() {
      let result = this.event.shipmentEventAttachments;
      result = this.documents.filter((x) =>
        result.map((y) => y.shipmentDocumentId).includes(x.id)
      );
      return result;
    },

  },
  async mounted() {
    if(this.event.destinationAddressId){
      this.adressData()
    }

    this.driver = new Driver({
      animate: false
    })


  },
  methods: {
    async adressData(){
      this.loadingAdress= true
      let adressId = this.event.destinationAddressId
      let result = await this.$API.getSingleAdress(adressId)
      // console.log('Adress', result)
      this.destinationAddress = result
      this.loadingAdress= false
    },
    selectStatusses(id) {
            let findIndex = this.selectedStatusses.indexOf(id)
            if (findIndex > -1) {
                this.selectedStatusses.splice(findIndex, 1)
            } else {
                this.selectedStatusses.push(id)
            }
        },
    formatAddress(address) {
      return address ? address + ' ' : '';
  },
    async downloadDocument(doc) {
      doc.loading = true;
      this.documentKey++;
      if (!doc.file && !doc.fileType) {
        let file = await this.$API.downloadFile({
          key: doc.key,
        });
        doc.file = file.file;
        doc.fileType = file.fileType;
      }
      var hiddenElement = document.createElement("a");
      hiddenElement.href = doc.file;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.name;
      hiddenElement.click();
      doc.loading = false;
      this.documentKey++;
    },
    findOpenWaybill(event) {
      this.waybillItem = {
        shipmentId: event.shipmentId,
        shipmentDocumentTypeId: event.shipmentDocumentTypeId
      }
      this.waybillSearch = true
      this.getOpenWaybills()
    },
    async getEventActions(id) {
      this.eventActions = await this.$API.getShipmentEventActions(id);
    },
    async getOpenWaybills() {
      this.loadingWaybill = true
      let obj = {
        id: this.event.shipmentId,
        statuses: this.selectedStatusses
      }
      this.openWaybills = await this.$API.getOpenWaybills(obj)
      console.log('Open waybills', this.openWaybills)
      this.loadingWaybill = false
    },
    getStatus(status) {
      switch (status) {
        case "Not started":
          return { name: status, icon: "schedule", color: "grey" };
        case "In Transit":
          return { name: status, icon: "local_shipping", color: "blue" };
        case "Delivered":
          return { name: status, icon: "task_alt", color: "green" };
        case "Pending":
          return { name: status, icon: "pending", color: "orange" };
        case "In Progress":
          return { name: status, icon: "sync", color: "blue" };
        case "Complete":
          return { name: status, icon: "task_alt", color: "green" };
        case "File Generated":
          return { name: status, icon: "task", color: "orange" };
        case "Success":
          return { name: status, icon: "task_alt", color: "green" };
        case "Cancelled":
          return { name: status, icon: "cancel", color: "grey" };
        case "Draft":
          return { name: status, icon: "edit_note", color: "orange" };
        case "Error":
          return { name: status, icon: "error", color: "red" };
        case "Failed":
          return { name: status, icon: "cancel", color: "red" };
        case "Submitted":
          return { name: status, icon: "mark_email_read", color: "blue" };
        case "Created":
          return { name: status, icon: "mark_email_read", color: "blue" };
        case "Not Required":
          return { name: status, icon: "close", color: "grey" };
        case "Approved":
          return { name: status, icon: "fact_check", color: "green" };
        default:
          return {
            icon: "info",
            color: "grey",
            name: status,
          };
      }
    },
    async executeAction(action) {
      this.loadingAction = action.code;
      let result = await this.$API.executeEvent(this.event.id, action.code, this.shipment.id);
      this.$emit("update", result);
      this.$message.success("Action executed successfully");
      this.$emit('refreshEvents')
      // this.getAttachments(this.event.id)
      this.loadingAction = null;
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(date, "dd/mm/yyyy HH:MM");
      }
      return result;
    },
    async getAttachments(id) {
      this.loadingAttachments = true;
      this.eventAttachments =
        await this.$API.getShipmentEventAttachments(id);
      this.loadingAttachments = false;
    },
    getDocumentIcon(name) {
      let type = name.split(".");
      let result = null;
      if (name.includes(".") && type.length > 0) {
        result = type.at(-1);
      }
      if (result == "xlsx") {
        result = "xls";
      }
      return result ? `/icons/${result}.svg` : null;
    },
    mergeWaybill(event) {
      this.$confirm(
        "Are you sure you want to merge this courier event with this waybill?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          await this.$API.updateShipmentEvent({
            id: this.event.id,
            isActive: false,
            isDeleted: true
          });
          await this.$API.createShipmentEventLink({
            shipmentId: this.waybillItem.shipmentId,
            shipmentDocumentTypeId: this.waybillItem.shipmentDocumentTypeId,
            shipmentEventId: event.id
          });
          this.$message({
            type: "success",
            message: "Successfully merged!"
          });
          this.waybillSearch = false
          this.waybillItem = {}
          this.$emit('refreshEvents')
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "Delete canceled",
          // });
        });
    },
    async deleteEvent(){
      this.deleteEventLoader = true
      console.log('Delete event',this.event)
      await this.$API.updateShipmentEvent({
        id: this.event.id,
        isActive: false,
        isDeleted: true
      });

      if(this.event.shipmentDocumentTypeId){
        await this.$API.updateShipmentDocumentType({
          id: this.event.shipmentDocumentTypeId,
          isActive: false,
          isDeleted: true
        });
      }
      this.deleteEventLoader = true

      this.$emit('refreshEvents')
    },
    async previewDocument(doc) {
      this.previewItem = doc;
      let file = await this.$API.downloadFile({
        key: doc.key,
      });
      this.previewItem.file = file.file;
      this.previewItem.fileType = file.fileType;
      this.previewModal = true;
    },
    async startEvent() {
      this.loading = true;
      let result = await this.$API.executeEvent(
        this.event.id,
        this.event.integrationCode,
        this.shipment.id
      );
      this.$emit("update", result);
      this.getAttachments(this.event.id)
      this.loading = false;
    },
    infoStartGuide() {
      if (this.infoStart) {
        this.infoStart = false;
      } else {
        this.infoStart = true;
      }
    },
    startGuide(e) {
      this.infoStart = false
      e.stopPropagation();
      this.driver.defineSteps(emSteps)
      this.driver.start()
    },
  },
};
</script>
