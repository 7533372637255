<template>
  <div>
    <v-tooltip top v-if="item && item.kpiCategory">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" v-if="item.kpiCategory == 'GOOD'" color="success">
          check_circle_outline
        </v-icon>
        <v-icon
          v-on="on"
          v-else-if="item.kpiCategory == 'DANGER'"
          color="orange"
        >
          warning
        </v-icon>
        <v-icon v-on="on" v-else-if="item.kpiCategory == 'OVERDUE'" color="red">
          error
        </v-icon>
      </template>
      <span style="font-size: 12px">{{ item.kpiCategory }}</span>
    </v-tooltip>
    <h4 v-else-if="group">
      <v-icon small class="mr-1" v-if="group == 'GOOD'" color="success">
        check_circle_outline
      </v-icon>
      <v-icon small class="mr-1" v-else-if="group == 'DANGER'" color="orange">
        warning
      </v-icon>
      <v-icon small class="mr-1" v-else-if="group == 'OVERDUE'" color="red">
        error
      </v-icon>
      <span style="font-size: 12px">{{ group }}</span>
    </h4>
  </div>
</template>
<script>
export default {
  data: () => ({
    item: {},
    group: null,
  }),
  mounted() {
    this.item = this.params.data;
    if (this.params.node && this.params.node.group && this.params.node.key) {
      this.group = this.params.value;
    }
  },
  methods: {},
};
</script>