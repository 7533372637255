const steps = [
  {
    element: "#event-description",
    popover: {
      title: "Event - Description",
      description: "This is the description of the chosen event",
      position: "left",
    },
  },
  {
    element: "#int-code",
    popover: {
      title: "Event - Integration Code",
      description: "This is the integration code of the chosen event",
      position: "left",
    },
  },
  {
    element: "#event-actions",
    popover: {
      title: "Event - Actions",
      description: "This displays all the event actions",
      position: "left",
    },
  },
  {
    element: "#event-attachments",
    popover: {
      title: "Event - Attachments",
      description: "This displays all the event attachments",
      position: "left",
    },
  },
];

export default steps;
