<template>
  <div>
    <v-btn-toggle
      v-if="item"
      borderless
      style="background-color: transparent; padding: 0px; margin: 0px"
      :max="0"
      multiple
      :value="[]"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon small @click="viewDocument">
            <v-icon small>open_in_new</v-icon>
          </v-btn>
        </template>
        <span style="font-size: 12px">View Document Details</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :loading="tableBillableStates[item.id]"
            v-bind="attrs"
            v-on="on"
            icon
            small
            @click="updateDocumentBillable(item)"
          >
            <v-icon small :color="item.isBillable ? 'green' : 'grey'">{{
              item.isBillable ? "paid" : "attach_money"
            }}</v-icon>
          </v-btn>
        </template>
        <span style="font-size: 12px">Change Billable Status</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            small
            color="red"
            @click="deleteShipmentDocumentType(item)"
          >
            <v-icon small color="red">delete</v-icon>
          </v-btn>
        </template>
        <span style="font-size: 12px">Delete Document</span>
      </v-tooltip>
    </v-btn-toggle>
  </div>
</template>
<script>

export default {
  data: () => ({
    fullItem: {},
    item: {},
    tableBillableStates: {},
    uploads: [],
  }),
  mounted() {
    this.fullItem = this.params;
    this.item = this.params.data;
  },
  methods: {
    viewDocument() {
      this.params.viewDocument(this.item);
    },
    async updateDocumentBillable() {
      this.params.updateDocumentBillable(this.item);
    },
    async deleteShipmentDocumentType() {
      this.params.deleteShipmentDocumentType(this.item);
    },
  },
};
</script>