<template>
  <div>
    <v-icon v-if="item && item.courier" color="success"
                            >check</v-icon
                          >
  </div>
</template>
<script>
export default {
  data: () => ({
    item: {},
  }),
  mounted() {
    this.item = this.params.data;
  },
  methods: {
   
  },
};
</script>