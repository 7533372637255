<template>
  <div>

  <div v-if="item">
    <v-menu
      transition="scale-transition"
      v-if="item && item.responsiblePartyType" :disabled="item && item.disableEdit"
    >
      <template v-slot:activator="{ on: menu, attrs: menuAttrs }">
        <v-tooltip top>
          <template v-slot:activator="{ on: tooltip, attrs: tooltipAttrs }">
            <v-icon
              small
              v-bind="{ ...menuAttrs, ...tooltipAttrs }"
              v-on="{ ...menu, ...tooltip }"
              >{{ getIcon(item.responsiblePartyType) }}</v-icon
            >
          </template>
          <span style="font-size: 12px; text-transform: capitalize">{{
            item.responsiblePartyType
          }}</span>
        </v-tooltip>
      </template>
      <v-card>
        <v-list dense>
          <v-list-item
            v-for="party in documentResponsibleParties"
            :key="party.id"
            @click="setDocumentResponsibleParty(party.value, item)"
          >
            <v-list-item-content>
              <v-list-item-title>{{ party.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
  <div v-else-if="group">
    <v-icon small class="mr-1"
              >{{ getIcon(group) }}</v-icon
            >
            {{ getName(group) }}
  </div>
</div>

</template>
  <script>
export default {
  data: () => ({
    documentResponsibleParties: [
      { id: 1, name: "Buyer", value: "buyer" },
      { id: 2, name: "Forwarder", value: "forwarder" },
      { id: 3, name: "Shipper", value: "shipper" },
      { id: 4, name: "Consignee", value: "consignee" },
    ],
    group: null,
    item: {},
  }),
  mounted() {
    this.item = this.params.data;
    if(this.item)
      this.item.disableEdit = this.params.disableEdit;
    if(this.params.node && this.params.node.group && this.params.node.key) {
      this.group = this.params.value;
    }
  },
  methods: {
    getIcon(type) {
      switch (type.toLowerCase()) {
        case "buyer":
          return "shopping_cart";
        case "consignee":
          return "call_received";
        case "shipper":
          return "directions_boat";
        case "forwarder":
          return "fast_forward";
        case "notify party":
          return "notifications";
      }
    },
    getName(type) {
      let party = this.documentResponsibleParties.find(
        (party) => party.value === type
      );
      return party ? party.name : "";
    },
    async setDocumentResponsibleParty(party, document) {
      document.responsiblePartyType = party;
      await this.$API.updateShipmentDocumentType(document);
      this.$message({
        type: "success",
        message: "Successfully updated!",
      });
    },
  },
};
</script>